import React, {useContext} from "react";
import GlobalContext from '../../contexto/GlobalContext'

import { Menubar } from "primereact/menubar";
import { Image } from "primereact/image";
import { Button } from "primereact/button";

const BarraMenu = () => {
  
  const {usuario, notificarCierreSesion, abrirVentanaCrearFia, abrirVentanaQueryFias} = useContext(GlobalContext);
  
  // console.log("usuario barra menu: ",usuario);

  const items = [
    {
      label: "FIAs",
      icon: "pi pi-fw pi-file",
      items: [
        {
          label: "Crear",
          icon: "pi pi-fw pi-plus",
          command: abrirVentanaCrearFia,
        },
        {
          label: "Listar",
          icon: "pi pi-fw pi-list",
          command: abrirVentanaQueryFias,
        },
      ],
    },
    {
      label: "Servicios Contratados",
      icon: "pi pi-fw pi-pencil",
      items: [
        {
          label: "Listar de Servicios Contrados",
          icon: "pi pi-fw pi-list",
        },
      ],
    },
    {
      label: "Usuarios",
      icon: "pi pi-fw pi-user",
      className: "p-submenu-icon",
      items: [
        {
          label: "Nuevo",
          icon: "pi pi-fw pi-user-plus",
        },

        {
          label: "Listar",
          icon: "pi pi-fw pi-users",
        },
      ],
    },
  ];

  //   return <Menubar model={items} />;
  return (
    <Menubar
      model={items}
      end={
        <div className="flex items-center rounded m-1 mr-10">
          <i className="pi pi-fw pi-user mr-2" style={{ fontSize: "3em" }}></i>
          <div className="flex flex-col items-center">
            <h1 className="mb-1">{usuario.alias}</h1>
            <Button
              label="Salir"
              icon="pi pi-power-off"
              className="p-1 m-0 w-20"
              onClick={notificarCierreSesion}
            />
          </div>
        </div>
      }
      className="mt-4 mx-4 p-0"
    />
  );
};
export default BarraMenu;
