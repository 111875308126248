const ActionTypes = {
  CREAR_VENTANA_CREAR_FIA:     0,
  ELIMINAR_VENTANA_CREAR_FIA:  1,
  CREAR_VENTANA_QUERY_FIAS:    2,
  ELIMINAR_VENTANA_QUERY_FIAS: 3,
  POSICIONAR_VENTANA_AL_TOP:   4,
  CREAR_VENTANA_QEDIT_FIA:     5,
  ELIMINAR_VENTANA_QEDIT_FIA:  6,
  ELIMINAR_VENTANA:  7,
};
export default ActionTypes;
