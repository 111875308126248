import { useState } from "react";

const PantallaLogin = ({
  nombreUsuario,
  contraseña,
  banderasError,
  setearNombreUsuario,
  setearContraseña,
  manejarLogueo,
}) => {
  // let clienteNoValido = false;
  // let usuarioVacio = false;
  // let contraseñaVacia = false;

  // console.log("evaluacion ", evaluacion);

  const manejarBotonSubmit = () => {
    manejarLogueo();
  };

  //   console.log(usuario,contraseña);
  // console.log(banderasError);
  let { usuarioNoValido, usuarioVacio, contraseñaVacia } = banderasError;
  //   console.log(clienteNoValido,usuarioVacio,contraseñaVacia);

  return (
    <div className="w-screen h-screen flex justify-center content-center">
      <div className="flex flex-col mx-auto my-auto w-full max-w-xs">
        <form className="bg-white shadow-md rounded px-4 pt-6 pb-6 mb-3">
          <div className="mb-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              htmlFor="username"
            >
              Usuario
            </label>
            <input
              className={`${
                usuarioVacio ? "border-red-500" : ""
              } shadow appearance-none border rounded w-full py-2 px-3 text-gray-700 leading-tight mb-2 focus:outline-none focus:shadow-outline`}
              id="username"
              type="text"
              placeholder="Nombre de Usuario"
              valor={nombreUsuario}
              onChange={({ target }) => {
                setearNombreUsuario(target.value);
              }}
            ></input>
            <p
              className={`text-red-500 text-xs italic ${
                usuarioVacio ? "" : "hidden"
              } `}
            >
              Por favor, ingresa un nombre de usuario.
            </p>
          </div>

          <div className="flex flex-column mb-3">
            <label
              className="block text-gray-700 text-sm font-bold mb-2"
              for="password"
            >
              Contraseña
            </label>
            <input
              className={`shadow appearance-none border ${
                contraseñaVacia ? "border-red-500" : ""
              } rounded w-full py-2 px-3 text-gray-700 mb-2 leading-tight focus:outline-none focus:shadow-outline`}
              id="password"
              type="password"
              placeholder="Ingresar Contraseña"
              value={contraseña}
              onChange={({ target }) => {
                setearContraseña(target.value);
              }}
            ></input>
            <p
              className={`text-red-500 text-xs italic ${
                contraseñaVacia ? "" : "hidden"
              } `}
            >
              Por favor, ingresa una contraseña.
            </p>
            <p
              className="inline-block align-baseline text-right mt-2 mb-2 font-bold text-xs text-blue-500 hover:text-blue-800"
              href="#"
            >
              ¿Ha olvidado su contraseña?
            </p>
          </div>

          <div
            className={`text-red-500 text-xs text-center italic ${
              usuarioNoValido ? "" : "hidden"
            } bg-red-100 appearance-none border border-red-300   
          rounded w-full py-2 px-3 mt-2 mb-2 leading-tight focus:outline-none focus:shadow-outline`}
          >
            Usuario no valido.
          </div>

          <div className="flex flex-column justify-center content-center">
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
              type="button"
              onClick={manejarBotonSubmit}
            >
              Ingresar
            </button>
          </div>
        </form>
        <p class="text-center text-gray-500 text-xs">
          &copy;2022 Fullfiber. Todos los derechos reservados.
        </p>
      </div>
    </div>
  );
};

export default PantallaLogin;
