import React, {useState, useEffect} from "react";
import GlobalContext from '../../contexto/GlobalContext'

import { Dialog } from "primereact/dialog";
import { DataTable} from "primereact/datatable"
import { Column} from "primereact/column"
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel} from "primereact/tabview";
import PrimeReact from 'primereact/api';
import { ZIndexUtils}  from 'primereact/utils';

import axios from 'axios'



const DialogQueryFIAs = ({ posicionZ, esconderme, enfocarme }) => {
  
  // const {posicionarPrimero, cerrarVentana} = useContext(GlobalContext);

  const [nroRenderizados,forceUpdate] = useState(0);
  
  if (nroRenderizados === 0) {
    posicionZ--;
  }
 
  const [fias, setFias] = useState([])
 
  const onShow = () =>{
    // console.log('onShow')
    forceUpdate(x => x+1);
    // setPosicionZ(posicionZ+1);
    // dialogoPrime.current.maskStyle={zIndex: ventana.posicionZ}
    // console.log('dialogoPrime: ',dialogoPrime.current.maskStyle);
  }

  const onClick =(evento)=>{

    if(evento==='CERRAR_DIALOGO'){
      console.log('hide')
      esconderme();
    }
    // console.log('on click')
    // console.log('posicionZ ',newPosicionZ)
    enfocarme();
  }

  const header = (
    <p className="p-2 bg-gradient-to-r from-purple-100">
      Lista de Fichas de Inscripcion de Abonado
    </p>
  );
  const footer = (
    <div>
      <Button label="Guardar" icon="pi pi-check" />
      <Button label="Cancelar" icon="pi pi-times" onClick={()=>onClick('CERRAR_DIALOGO')}/>
    </div>
  );

  useEffect(() => {
      buscar();
  }, [])

  const buscar = async ()=>{
    const res =  await axios.get('http://localhost:3001/fias');
    setFias(res.data);
    console.log(res.data);
    
  }

  // console.log('posicion z: ',posicionZ);
  const dialogo = <Dialog
      header={header}
      visible={true}
      // style={{ width: "50vw"}}
      modal={false}
      footer={footer}
      onHide={()=>onClick('CERRAR_DIALOGO')}
      onShow={onShow}
      onClick={()=>onClick('CLICK_PANEL')}
      keepInViewport={false}
      maskStyle={{zIndex: posicionZ}}
    >
      <DataTable value={fias} showGridlines stripedRows size="small" autoLayout={true} responsiveLayout="scroll">
          <Column field="codigoAbonado" header="Cod. Abonado" style={{ minWidth: '1rem' }}></Column>
          <Column field="nombres" header="Nombres" style={{ minWidth: '1rem' }}></Column>
          <Column field="apellidos" header="Apellidos" style={{ minWidth: '1rem' }}></Column>
          <Column field="dni" header="DNI" style={{ minWidth: '1rem' }}></Column>
          <Column field="ptp" header="PTP" style={{ minWidth: '1rem' }}></Column>
          <Column field="carnetext" header="C. Extranjería" style={{ minWidth: '1rem' }}></Column>
          <Column field="pasaporte" header="Pasaporte" style={{ minWidth: '1rem' }}></Column>
          <Column field="razonsocial" header="Razón Social" style={{ minWidth: '1rem' }}></Column>
          <Column field="ruc" header="RUC" style={{ minWidth: '1rem' }}></Column>
      </DataTable>     
    </Dialog>
  
  // console.log(dialogo);
    // console.log(ventana.posicionZ);
  // return <div id="prueba" style={{zIndex: ventana.posicionZ}}>{dialogo}</div>;
  return dialogo;

};

export default DialogQueryFIAs;
