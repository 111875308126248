import axios from "axios";

const loguear = async ({ nombreUsuario, contraseña }) => {
    const [respAxiosExito, respAxiosError] = await hacerRequerimiento({
        nombreUsuario,
        contraseña,
    });

    if (respAxiosExito) {
        const [usuario, error] =
        parsearPayloadDeRespuestaExitosa(respAxiosExito);
        if (usuario) {
            return [usuario, false, false];
        }
        if (error) {
            return [false, false, error];
        }
    }

    if (respAxiosError) {
        const [codigoErrorAPI, error] = parsearRespuestaErronea(respAxiosError);
        if (codigoErrorAPI) {
        return [false, codigoErrorAPI, false];
        }
        if (error) {
        return [false, false, error];
        }
    }
};

const hacerRequerimiento = async ({ nombreUsuario, contraseña }) => {
  try {
    // const baseUrl = "http://127.0.0.1:3001/login";
    // const baseUrl = "http://127.0.0.1:3005/index.php/login";
    console.log("entro 2022 a");
    const baseUrl = process.env.REACT_APP_API_SERVER+'/login';
    console.log("entro 2022 b");
    console.log(baseUrl);
    // const resLogueo = {error:false, data:{nombre:"Victor Dionisio", alias:"yoni", token:"0064156sd1fsdf1sd6f"}}
    const respAxios = await axios({
      method: "post",
      url: baseUrl,
      data: {
        nombreUsuario: nombreUsuario,
        contraseña: contraseña,
      },
      headers: {
        "Content-Type": "application/json",
        // "Access-Control-Allow-Origin": "*",
        // "Content-Type": "application/x-www-form-urlencoded",
      },
    });
    // console.log("respLogueo ", resp);
    return [respAxios, false];
  } catch (e) {
    // evaluacion.clienteNoValido = true;

    // console.log("E.request ", e.request);
    return [false, e.request];
    // const { response: respLogueo } = JSON.parse(e.request.response);
    // console.log("Request Response ", e.request.response);
  }
};

const parsearPayloadDeRespuestaExitosa = (respAxiosExito) => {
  const respuestaApi = respAxiosExito.data;
  try {
    //parsear respuestaApi
    const usuario = respuestaApi.data;
    return [usuario, false];
  } catch (error) {
    //Si hay error en parseo:
    return [
      false,
      {
        idError: "AW001",
        mensaje: "Se recibio un Usuario con un formato no aceptable",
      },
    ];
  }
};

const parsearRespuestaErronea = (respAxiosError) => {
  // console.log(respErrorAParsear);
    const HTTP_UNAUTHORIZED = 401;
    const HTTP_BAD_REQUEST = 400;
    const HTTP_INTERNAL_SERVER_ERROR=500;
    
    const esCodigoHttpDelAPI = 
        respAxiosError.status === HTTP_UNAUTHORIZED ||
        respAxiosError.status === HTTP_BAD_REQUEST ||
        respAxiosError.status === HTTP_INTERNAL_SERVER_ERROR 
    
    if (esCodigoHttpDelAPI){

        const respuestaApiString = respAxiosError.response;

        try {
            const respuestaApi = JSON.parse(respuestaApiString);
            //parsear respuestaApi
            const codigoErrorAPI = respuestaApi.error['idError'];
            return [codigoErrorAPI, false];
        }catch (error) {
        //Si hay error en parseo:
            return [
            false,
            {
            idError: "AW002",
            mensaje: "Se recibio error con un formato no aceptable",
            },
        ];
        // return [false,{idError:"02", mensaje:"No hubo conexion con el Servidor"}];
        }
    }

    return [
        false,
        { idError: "AW003", mensaje: "No hubo conexion con el Servidor" },
    ];
};

export default loguear;
