import React, {useState, useContext} from "react";
import GlobalContext from '../../contexto/GlobalContext'
import DialogQueryFIAs from '../pantallas/DialogQueryFIAs'

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel} from "primereact/tabview";
import PrimeReact from 'primereact/api';
import { ZIndexUtils}  from 'primereact/utils';



const PresentadorDialogQueryFIAs = ({ventana}) => {
  
  const {posicionarPrimero, cerrarVentana} = useContext(GlobalContext);
  
  const enfocarme = () => {
    posicionarPrimero(ventana.id);
  };

  const esconderme = () => {
    cerrarVentana(ventana.id);
  };

  return <DialogQueryFIAs 
      posicionZ={ventana.posicionZ}
      enfocarme={enfocarme}
      esconderme={esconderme}
    />;

};

export default PresentadorDialogQueryFIAs;
