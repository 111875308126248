import ActionTypes from './ActionTypes'

export default function appReducer(state, { type, payload }) {
  // console.log(state, type, payload);
  console.log(payload);

  switch (type) {
    case ActionTypes.CREAR_VENTANA_CREAR_FIA:
      return { ventanas: [...state.ventanas, payload] };

    case ActionTypes.CREAR_VENTANA_QUERY_FIAS:
      return { ventanas: [...state.ventanas, payload] };
    
    case ActionTypes.ELIMINAR_VENTANA:
      return {
        ventanas: state.ventanas.filter((ventana) => ventana.id !== payload),
      };

    case ActionTypes.POSICIONAR_VENTANA_AL_TOP:
      const intercambiarPosicionesZ = (indice1, indice2) => {
        const posicionZIndice1 = state.ventanas[indice1].posicionZ;
        state.ventanas[indice1].posicionZ = state.ventanas[indice2].posicionZ;
        state.ventanas[indice2].posicionZ = posicionZIndice1;
      };

      const intercambiarVentanas = (indice1, indice2) => {
        const ventana1 = state.ventanas[indice1];
        const ventana2 = state.ventanas[indice2];

        state.ventanas.splice(indice1, 1, ventana2);
        state.ventanas.splice(indice2, 1, ventana1);
      };

      console.log('reducer ',payload)
      const indiceVentanaAPosicionar = payload;
      const indiceVentanaTop = state.ventanas.length - 1;

      intercambiarPosicionesZ(indiceVentanaAPosicionar, indiceVentanaTop);
      intercambiarVentanas(indiceVentanaAPosicionar, indiceVentanaTop);

      console.log('ventanas ', state.ventanas)
      return { ventanas: [...state.ventanas] };

    case "REGISTRAR_FIA":
      return { tasks: [...state.tasks, payload] };

    case "UPDATE_TASK":
      return {
        tasks: state.tasks.map((task) => {
          if (task.id === payload.id) {
            return payload;
          } else {
            return task;
          }
        }),
      };

    case "TOGGLE_TASK_DONE":
      console.log("task toggle done");

      return {
        tasks: state.tasks.map((task) => {
          if (task.id === payload) {
            console.log(task);
            // task.done = !task.done;
            // task.id=`${Math.floor(3+Math.random() * 2)}`;
            // task.done= Math.floor(Math.random() * 2) ? false: true;
            // const toggle = Math.floor(Math.random() * 2) ? true : false;
            let toggle = true;
            if (task.done) {
              toggle = false;
            } else {
              toggle = true;
            }
            console.log("toogle: " + toggle);
            // task.done = toggle;
            // task.title= "basofia";
            // task.id=555555555
            // const newTask = { id: task.id,
            // done: !task.done, title: task.title,
            // description: task.description };
            const newTask = { ...task, done: !task.done };
            // console.log(newTask);
            console.log(task);
            // return task;
            return newTask;
            // return {...task, done: !task.done};
          } else {
            return task;
          }
        }),
      };

    default:
      break;
  }
}
