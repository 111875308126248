import React, { useState, useContext } from "react";
import GlobalContext from "../../contexto/GlobalContext";
import DialogFIA from "../pantallas/DialogFIA";
import axios from 'axios'

const PresentadorDialogFIA = ({ ventana }) => {
  const { posicionarPrimero, cerrarVentana, notificarRegistroDeFia } = useContext(GlobalContext);

  // console.log("ventana ", ventana);
  //   const deleteTask = (id) => {
  //console.log(task);
  // dispatch({ type: "DELETE_TASK", payload: id });
  //   };

  //   const updateTask = (task) => dispatch({ type: "UPDATE_TASK", payload: task });

  //   const toggleTaskDone = (id) =>
  // dispatch({ type: "TOGGLE_TASK_DONE", payload: id });

  const enfocarme = () => {
    posicionarPrimero(ventana.id);
  };

  const registrarFia = async (newfia) => {
    const res =  await axios.get('http://localhost:3001/fias');
    console.log(res.data);
    notificarRegistroDeFia();
  };

  const esconderme = () => {
    cerrarVentana(ventana.id);
  };

  return (
    <DialogFIA
      posicionZ={ventana.posicionZ}
      enfocarme={enfocarme}
      registrarFia={registrarFia}
      esconderme={esconderme}
    />
  );
};

export default PresentadorDialogFIA;
