import React, { useState, useContext } from "react";
import GlobalContext from "../../contexto/GlobalContext";

import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import { RadioButton } from "primereact/radiobutton";
import { InputText } from "primereact/inputtext";
import { TabView, TabPanel } from "primereact/tabview";

const DialogFIA = ({ posicionZ, registrarFia, esconderme, enfocarme }) => {
  // const { posicionarPrimero, cerrarVentana, registrarFia } = useContext(GlobalContext);

  /* console.log("esconderme ",posicionZ);
  console.log("esconderme ",esconderme);
  console.log("esconderme ",esconderme);
  console.log("enfocarme ",enfocarme); */

  const [activeIndex, setActiveIndex] = useState(0);

  const [nroRenderizados, forceUpdate] = useState(0);

  const [newFia, setNuevaFia] = useState({
    nacionalidad: "peru",
    tipoDocumento: "dni",
    nroDni: "42168751",
    nombres: "Jose Carlos",
    apellidos: "Dionisio Minaya",
    estado_servicio: "Por instalar",
  });

  if (nroRenderizados === 0) {
    posicionZ--;
  }

  // console.log("Render");

  const onShow = () => {
    // console.log("onShow");
    forceUpdate((x) => x + 1);
    // setPosicionZ(posicionZ+1);
    // dialogoPrime.current.maskStyle={zIndex: ventana.posicionZ}
    // console.log('dialogoPrime: ',dialogoPrime.current.maskStyle);
  };

  const onClick = (evento) => {
    if (evento === "CERRAR_DIALOGO") {
      // console.log("hide");
      esconderme();
    }

    if (evento === "GUARDAR_FIA") {
      console.log("GUARDAR FIA");
      // cerrarVentana(ventana.id);
      registrarFia(newFia);
    }

    // console.log("on click");
    // console.log("posicionZ ", newPosicionZ);
    enfocarme();
  };

  const header = (
    <p className="p-2 bg-gradient-to-r from-purple-100">
      Crear Ficha de Inscripcion de Abonado
    </p>
  );

  const footer = (
    <div>
      <Button label="Guardar" icon="pi pi-check"onClick={() => onClick("GUARDAR_FIA")}/>
      <Button
        label="Cancelar"
        icon="pi pi-times"
        onClick={() => onClick("CERRAR_DIALOGO")}
      />
    </div>
  );

  // console.log('posicion z: ',posicionZ);
  const dialogo = (
    <Dialog
      header={header}
      visible={true}
      style={{ width: "50vw" }}
      modal={false}
      footer={footer}
      onHide={() => onClick("CERRAR_DIALOGO")}
      onShow={onShow}
      onClick={() => onClick("CLICK_PANEL")}
      keepInViewport={false}
      maskStyle={{ zIndex: posicionZ }}
      // baseZIndex={posicionZ}
      // maskStyle={{zIndex: ignored}}
      // maskClassName={"z-0"}
      // focusOnShow={false}
      // autoFocus={true}
      // autoZIndex={false}
      // appendTo={'self'}
      // ref={dialogoPrime}
    >
      <TabView
        activeIndex={activeIndex}
        onTabChange={(e) => setActiveIndex(e.index)}
      >
        <TabPanel header="Datos del Cliente">
          <p className="text-lg font-semibold mb-4">Tipo de Cliente</p>
          <div className="field-radiobutton">
            <RadioButton
              inputId="conDni"
              name="tipo"
              value="val1"
              checked={true}
            />
            <label htmlFor="natural">Con DNI</label>
          </div>

          <div className="field-radiobutton">
            <RadioButton
              inputId="conRuc"
              name="tipo"
              value="val2"
              checked={false}
            />
            <label htmlFor="emp">Con RUC</label>
          </div>

          <div className="field-radiobutton">
            <RadioButton
              inputId="conCarEx"
              name="tipo"
              value="val3"
              checked={false}
            />
            <label htmlFor="emp">Con Carnet de Extranjería</label>
          </div>

          <p className="text-lg font-semibold mt-5 mb-3">Datos del Cliente</p>
          <div className="field">
            <label htmlFor="nombres" className="block">
              Nombres
            </label>
            <InputText id="nombres" className="block w-5" />
          </div>
          <div className="field">
            <label htmlFor="nombres" className="block">
              Apellidos
            </label>
            <InputText id="nombres" className="block w-5" />
          </div>
          <div className="field">
            <label htmlFor="nombres" className="block">
              DNI
            </label>
            <InputText id="nombres" className="block w-2" maxLength="8" />
          </div>
        </TabPanel>

        <TabPanel header="Datos del Servicio a Contratar">
          <p className="text-lg font-semibold mt-5 mb-3">
            Datos del Servicio a Contratar
          </p>
          <div className="field">
            <label htmlFor="nombres" className="block">
              Plan a Elegir
            </label>
            <InputText id="nombres" className="block w-4" />
          </div>
          <div className="field">
            <label htmlFor="nombres" className="block">
              Direccion de Instalacion
            </label>
            <InputText id="nombres" className="block w-10" />
          </div>
          <div className="field">
            <label htmlFor="nombres" className="block">
              Direccion de Cobro
            </label>
            <InputText id="nombres" className="block w-10" />
          </div>
        </TabPanel>

        <TabPanel header="Área Técnica">Área de Técnica</TabPanel>
      </TabView>
    </Dialog>
  );

  return dialogo;
};

export default DialogFIA;
