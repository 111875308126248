import React, { useReducer, useContext } from "react";
import GlobalContext from "../../contexto/GlobalContext";
import appReducer from "../../reductores/AppReducer";
import { v4 } from "uuid";

import PantallaPrincipal from "../pantallas/PantallaPrincipal";
import PresentadorDialogFIA from "./PresentadorDialogFIA";
import PresentadorDialogQueryFIAs from "./PresentadorDialogQueryFIAs";
import DialogTypes from "../pantallas/DialogTypes";
import ActionTypes from "../../reductores/ActionTypes";

import PrimeReact from 'primereact/api';

const PresentadorPrincipal = () => {
  
  const initialState = {
    ventanas: [],
  };
  
  const [state, dispatch] = useReducer(appReducer, initialState);


  PrimeReact.autoZIndex = false;

  const abrirVentanaQueryFias = () => {
    
    const ventanaQueryFias = state.ventanas.find((ventana) =>
      ventana.tipo === DialogTypes.QUERY_FIAS ? true : false
    );

    if (ventanaQueryFias) {
      posicionarPrimero(ventanaQueryFias.id);
    } else {
      // console.log();
      dispatch({
        type: ActionTypes.CREAR_VENTANA_QUERY_FIAS,
        payload: {
          tipo: DialogTypes.QUERY_FIAS,
          id: v4(),
          evento: null,
          posicionZ: state.ventanas.length + 1,
        },
      });
    }
  };

  /* const cerrarVentanaQueryFias = (id) => {
    dispatch({ type: ActionTypes.ELIMINAR_VENTANA_QUERY_FIAS, payload: id });
  }; */

  const abrirVentanaCrearFia = () => {
    const ventanaCrearFia = state.ventanas.find((ventana) =>
      ventana.tipo === DialogTypes.CREAR_FIA ? true : false
    );
    console.log("entro abrir ventana", ventanaCrearFia);
    if (ventanaCrearFia) {
      posicionarPrimero(ventanaCrearFia.id);
    } else {
      console.log();
      dispatch({
        type: ActionTypes.CREAR_VENTANA_CREAR_FIA,
        payload: {
          tipo: DialogTypes.CREAR_FIA,
          id: v4(),
          evento: null,
          posicionZ: state.ventanas.length + 1,
        },
      });
    }
  };

  /*  const cerrarVentanaCrearFia = (id) => {
    dispatch({ type: ActionTypes.ELIMINAR_VENTANA_CREAR_FIA, payload: id });
  }; */

  const cerrarVentana = (id) => {
    dispatch({ type: ActionTypes.ELIMINAR_VENTANA, payload: id });
  };

  const posicionarPrimero = (idVentana) => {
    const idVentanaTop = state.ventanas[state.ventanas.length - 1].id;
    const esVentanaTop = idVentana === idVentanaTop;
    if (!esVentanaTop) {
      // let indiceVentanaAPosicionar = 0
      state.ventanas.some((ventana, indice) => {
        if (idVentana === ventana.id) {
          console.log("iterador");
          // indiceVentanaAPosicionar = indice;
          dispatch({
            type: ActionTypes.POSICIONAR_VENTANA_AL_TOP,
            payload: indice,
          });
          return true;
        }
        return false;
      });
      // dispatch({ type: "POSICIONAR_VENTANA", payload: indiceVentanaAPosicionar });
    }
  };

  const notificarRegistroDeFia = ()=>{

  }

  const lista_ventanas = state.ventanas.map((ventana) => {
    if (
      ventana.tipo === DialogTypes.CREAR_FIA ||
      ventana.tipo === DialogTypes.QEDITAR_FIA
    ) {
      return <PresentadorDialogFIA key={ventana.id} ventana={ventana} />;
    }

    if (ventana.tipo === DialogTypes.QUERY_FIAS) {
      return (
        <PresentadorDialogQueryFIAs key={ventana.id} ventana={ventana} />
      );
    }

    return null;
  });

  const value = useContext(GlobalContext);

  // console.log("presentador principal:",value)

  return (
    <GlobalContext.Provider
    value={{
      ...value,
      lista_ventanas,
      abrirVentanaCrearFia,
      abrirVentanaQueryFias,
      cerrarVentana,
      posicionarPrimero,
      notificarRegistroDeFia,
      
      }}
    >
      <PantallaPrincipal />
    </GlobalContext.Provider>
  );
};

export default PresentadorPrincipal;
