
// import "primereact/resources/themes/lara-light-indigo/theme.css"
import "primereact/resources/themes/tailwind-light/theme.css"
import "primereact/resources/primereact.min.css"
import "primeicons/primeicons.css"
import 'primeflex/primeflex.css';
import './tailwind.css';
import "./App.css";

import React from 'react'
import {BrowserRouter} from 'react-router-dom'
import PresentadorApp from './componentes/presentadores/PresentadorApp'

function App() {  
    return <BrowserRouter>
              <React.StrictMode>
                  <PresentadorApp />
              </React.StrictMode>
           </BrowserRouter>
}

export default App;
