import {useState} from 'react'
import {Routes, Route} from 'react-router-dom'
import { useNavigate, Navigate } from "react-router-dom";

import PresentadorPrincipal from './PresentadorPrincipal'
import PresentadorLogueo from './PresentadorLogueo'

import GlobalContext from "../../contexto/GlobalContext";

function PresentadorApp() {
  
  const [usuario, setUsuario] = useState(null)
  const navigate = useNavigate();

  console.log("usuario despues de cargar: ",usuario)

  if(usuario === null){
      const presentadorLogueoConfigurado = configurarPresentadorLogueo({setUsuario,navigate});
      return traerRuteadorQueLlevaAlPresentadorLogueo({presentadorLogueoConfigurado});
  }else{
      const presentadorPrincipalConfigurado = configurarPresentadorPrincipal({usuario,setUsuario,navigate});
      return traerRuteadorQueLlevaAlPresentadorPrincipal({presentadorPrincipalConfigurado});
  }

}

const configurarPresentadorLogueo = ({setUsuario, navigate}) => {
  
  const notificarLogueo = (usuarioValidado)=>{
      console.log("logue - usuario: ",usuarioValidado)
      setUsuario(usuarioValidado);
      navigate("/",{replace:true});
  }

  return <PresentadorLogueo notificarLogueo={notificarLogueo} />
}

const traerRuteadorQueLlevaAlPresentadorLogueo = ({presentadorLogueoConfigurado}) => {

  return (
    <Routes>
      <Route path="/login" element={presentadorLogueoConfigurado} />
      <Route path="*" element={ <Navigate to="login" /> } />
    </Routes>
  );
}

const configurarPresentadorPrincipal = ({usuario,setUsuario, navigate}) => {
  
  const notificarCierreSesion = () =>{
      setUsuario(null);
      navigate("/login",{replace:true}); 
    }

  return <GlobalContext.Provider value={{usuario, notificarCierreSesion}}>
            <PresentadorPrincipal />
        </GlobalContext.Provider>
}

const traerRuteadorQueLlevaAlPresentadorPrincipal = ({presentadorPrincipalConfigurado}) => {

  return (
    <Routes>
      <Route path="*" element={ <Navigate to="/" /> } />
      <Route path="/" element={ presentadorPrincipalConfigurado } />
    </Routes>
  );

}

export default PresentadorApp;
