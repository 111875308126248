import React, { useContext } from "react";
import GlobalContext from "../../contexto/GlobalContext";
import BarraMenu from "./BarraMenu";
import { Toast } from 'primereact/toast';



const PantallaPrincipal = () => {
  const { lista_ventanas } = useContext(GlobalContext);

  // console.log("usuario pantalla principal",usuario)

  return (
    <>
      <BarraMenu />
      {lista_ventanas}
    </>
  );
};

export default PantallaPrincipal;
