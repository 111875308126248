import { useState } from "react";

import PantallaLogin from "../pantallas/PantallaLogin";
import login from "../../servicios/login";

const PresentadorLogueo = ({ notificarLogueo }) => {
  // const [evaluacion, setEvaluacion] = useState(null);
  const [nombreUsuario, setNombreUsuario] = useState("");
  const [contraseña, setContraseña] = useState("");
  const [banderasError, setBanderasError] = useState({
    usuarioNoValido: false,
    usuarioVacio: false,
    contraseñaVacia: false,
  });
  // const [evaluacion, setEvaluacion] = useState({clienteNoValido:false, usuarioVacio:false, contraseñaVacia:false});
  //console.log("entro presentador logueo?")

  const manejarLogueo = async () => {
    const [usuario, codigoErrorAPI, error] = await login({
        nombreUsuario,
        contraseña,
    });
    console.log(usuario, codigoErrorAPI, error);

    if (usuario) {
        notificarLogueo(usuario);
    }

    if (codigoErrorAPI) {
        switch(codigoErrorAPI){
            case 'APIIA0001':{
                setBanderasError({usuarioNoValido:true,usuarioVacio:false,contraseñaVacia:false});
                break;
            }case 'APIIA0002':{
                setBanderasError({usuarioNoValido:false,usuarioVacio:true,contraseñaVacia:false});
                break;
            }case 'APIIA0003':{
                setBanderasError({usuarioNoValido:false,usuarioVacio:false,contraseñaVacia:true});
                break;
            }case 'APIIA0004':{
                setBanderasError({usuarioNoValido:false,usuarioVacio:true,contraseñaVacia:true});
                break;
            }case 'APIIA0005':{
                setBanderasError({usuarioNoValido:false,usuarioVacio:false,contraseñaVacia:false});
                break;
            }case 'APIIA0006':{
                setBanderasError({usuarioNoValido:false,usuarioVacio:false,contraseñaVacia:false});
                break;
            }default:

        }
    }

    if (error) {
      setBanderasError({
        usuarioNoValido: false,
        usuarioVacio: false,
        contraseñaVacia: false,
      });
      // console.log(errorApp);
    }
    // setEvaluacion({clienteNoValido:false, usuarioVacio:true, contraseñaVacia:false})
  };

  const setearNombreUsuario = (nombreUsuario) => {
    setNombreUsuario(nombreUsuario);
    setBanderasError({ ...banderasError, usuarioVacio: false });
  };
  const setearContraseña = (contraseña) => {
    setContraseña(contraseña);
    setBanderasError({ ...banderasError, contraseñaVacia: false });
  };

  return (
    <PantallaLogin
      nombreUsuario={nombreUsuario}
      contraseña={contraseña}
      banderasError={banderasError}
      setearNombreUsuario={setearNombreUsuario}
      setearContraseña={setearContraseña}
      manejarLogueo={manejarLogueo}
    />
  );
};

export default PresentadorLogueo;
